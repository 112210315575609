
import type { PropType } from 'vue'
import { defineComponent } from 'vue'
import TmTable from '@/components/shared/table/TmTable.vue'
import TmFile from '@/components/shared/TmFile.vue'
import TmStatus from '@/components/shared/TmStatus.vue'
import TmButton from '@/components/shared/TmButton.vue'
import Highlight from '@/components/shared/Highlight.vue'
import ContactsImportDropdown from '@/components/pages/contacts/import/ContactsImportDropdown.vue'
import { formatNumber } from '@/services/utils'
import type { TableHeaders } from '@/definitions/shared/types'

export default defineComponent({
  name: 'ContactsImportTable',
  components: {
    TmTable,
    TmFile,
    TmStatus,
    TmButton,
    Highlight,
    ContactsImportDropdown,
  },
  props: {
    headers: {
      type: Array as PropType<TableHeaders[]>,
      required: true,
    },
    items: {
      type: Array,
      required: true,
    },
    itemsName: {
      type: String,
      required: true,
    },
    itemKey: {
      type: String,
      default: 'id',
    },
    showSelection: {
      type: Boolean,
    },
    sortColumnName: {
      type: String,
    },
    columnSortOrder: {
      type: String,
    },
    search: {
      type: String,
      default: '',
    },
  },
  setup() {
    return {
      formatNumber,
    }
  },
})
