
import { computed, defineComponent, ref } from 'vue'
import PageContent from '@/components/layout/pageContent/PageContent.vue'
import ContactsImportTable from '@/components/pages/contacts/import/ContactsImportTable.vue'
import ContactsImportFilter from '@/components/pages/contacts/import/ContactsImportFilter.vue'
import ContactsImportEmpty from '@/components/pages/contacts/import/ContactsImportEmpty.vue'
import { getTableData } from '@/services/tableService'
import { formatDate } from '@/services/dateTimeService'
import type { TableHeaders } from '@/definitions/shared/types'
import type { ContactImport } from '@/definitions/contacts/import/types'
import { useModes } from '@/compositions/modes'

export default defineComponent({
  components: {
    PageContent,
    ContactsImportTable,
    ContactsImportFilter,
    ContactsImportEmpty,
  },
  props: {
    title: {
      type: String,
    },
  },
  setup() {
    const { isEmptyMode } = useModes()
    const search = ref('')
    const selected = ref<ContactImport[]>([])

    const tableHeaders = ref<TableHeaders[]>([
      { text: 'File name', value: 'name-slot' },
      { text: 'Imported rows', value: 'imported-slot' },
      { text: 'Failed rows', value: 'failed-slot' },
      { text: 'Status', value: 'status-slot' },
      { text: 'Date imported', value: 'dateCreated', width: '12%', format: (val: string) => formatDate(val) },
      { text: '', value: 'active', width: '1%', hideSortable: true },
    ])
    const tableItems = ref<ContactImport[]>(getTableData('contactsImport'))
    const filteredTableItems = computed<ContactImport[]>(() => {
      return tableItems.value.filter((item) => item.fileName.toLowerCase().includes(search.value.toLowerCase()))
    })

    return {
      search,
      selected,
      tableHeaders,
      tableItems,
      filteredTableItems,
      isEmptyMode,
    }
  },
})
